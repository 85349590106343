// Copyright © 2025 Ory Corp
// SPDX-License-Identifier: Apache-2.0
import { useEffect, useState } from "react"

export const usePrefersReducedMotion = () => {
  const [preference, setPreference] = useState(true)

  useEffect(() => {
    setPreference(
      window.matchMedia("(prefers-reduced-motion: reduce)").matches === true,
    )
  }, [])

  return preference
}
