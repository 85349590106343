// Copyright © 2025 Ory Corp
// SPDX-License-Identifier: Apache-2.0
import { useEffect, useState } from "react"
import { DockerRepositoryResponse, GithubOrg } from "./statistics.types"

const dockerOrgs = ["oryd", "oryam"]
const dockerUrl = (org: string) =>
  `https://corsar.ory.sh/v2/repositories/${org}/?__host=hub.docker.com&__proto=https&page_size=100`

export const useStatistics = () => {
  const [github, setGithub] = useState(0)
  const [docker, setDocker] = useState(0)
  // TODO: Fetching requests is not possible in this context without permissions -> using static value
  const [requests, setRequests] = useState(1366558941153)

  useEffect(() => {
    setDocker(0)
    dockerOrgs.forEach((org) => {
      fetch(dockerUrl(org))
        .then(
          async (res) =>
            (await res.json()) as unknown as DockerRepositoryResponse,
        )
        .then((response) => {
          const count = response.results.reduce((p, n) => p + n.pull_count, 0)
          setDocker((prev) => prev + count)
        })
    })
  }, [])

  useEffect(() => {
    let page = 1
    setGithub(0)

    const fetchPage = () =>
      fetch(
        `https://corsar.ory.sh/orgs/ory/repos?__host=api.github.com&__proto=https&per_page=100&page=${page}`,
      ).then(async (res) => (await res.json()) as unknown as GithubOrg[])

    const fetchAll = () => {
      fetchPage().then((repos) => {
        repos.forEach(({ stargazers_count }) => {
          setGithub((prev) => prev + stargazers_count)
        })

        if (repos.length >= 100) {
          page++
          fetchAll()
        }
      })
    }

    fetchAll()
  }, [])

  return {
    github,
    docker,
    requests,
  }
}
