import { Button } from "@ory/elements"
import classNames from "classnames"
import { graphql } from "gatsby"
import { CheckCircle } from "phosphor-react"
import { useState } from "react"
import {
  ShieldCheck,
  BrandOpenSource,
  TrendingUp,
  Icon,
  ChevronDown,
} from "tabler-icons-react"
import z from "zod"
import Container from "../../../freestanding/container"
import Section from "../../../freestanding/section"
import Overline, { overlineSchema } from "../overline/overline"

function getUTMParams() {
  const params = new URLSearchParams(window.location.search)
  return {
    utm_source: params.get("utm_source") || "",
    utm_medium: params.get("utm_medium") || "",
    utm_campaign: params.get("utm_campaign") || "",
  }
}

const contactFormSchema = z.object({
  jobtitle: z.string({ required_error: "Please select your title" }),
  firstname: z.string({ required_error: "Please enter your name" }),
  email: z
    .string({
      required_error: "Please enter your email",
    })
    .email({ message: "Please enter a valid email" }),
  what_are_you_interested_in_find_out_: z.string().optional(),
})

type ContactFormObject = z.infer<typeof contactFormSchema>
type ContactFormError = z.inferFormattedError<typeof contactFormSchema>

const submitHubspotForm = (data: ContactFormObject) => {
  const portalId = "25092455"
  const formGuid = "b179aa23-5b5f-4827-93ca-db770dd622ac"
  const utm = getUTMParams()

  const fields = {
    ...data,
    ...utm,
  }

  fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        submittedAt: Date.now(),
        fields: Object.entries(fields).map(([name, value]) => ({
          objectTypeId: "0-1",
          name,
          value,
        })),
      }),
    },
  )
}

const useForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState<Partial<ContactFormError>>({})
  const [fields, setFields] = useState<Partial<ContactFormObject>>({})

  const register = (name: keyof ContactFormObject) => {
    return {
      onChange: (value: string) => {
        setFields({ ...fields, [name]: value })
      },
    }
  }

  const handleSubmit = () => {
    const result = contactFormSchema.safeParse(fields)

    if (!result.success) {
      const { _errors, ...fieldsErrors } = result.error.format()
      setErrors(fieldsErrors)
      return
    }

    setErrors({})
    submitHubspotForm(result.data)
    setSubmitted(true)
  }

  return {
    errors,
    register,
    submitted,
    handleSubmit,
  }
}

const titleOptions = [
  { value: "", label: "Please select your title", hidden: true },
  { value: "engineer", label: "Engineer" },
  { value: "manager", label: "Product manager" },
  { value: "owner", label: "Product owner" },
  { value: "sales", label: "Sales Representative" },
  { value: "cto", label: "CTO" },
  { value: "ceo", label: "CEO" },
  { value: "other", label: "Other" },
]

const reasonOptions = [
  { value: "", label: "Please select a motivation", hidden: true },
  { label: "Building a new product / service", value: "Product" },
  { label: "Moving away from other SaaS solution", value: "Migration support" },
  {
    label: "Customer/B2C identity management & authentication",
    value: "Consumer (B2C) identity management & authentication",
  },
  {
    label: "B2B identity management & authentication",
    value: "B2B identity management & authentication",
  },
  {
    label: "Permissions and Access Control",
    value: "Permissions and Access Control",
  },
  {
    label: "Migrating from self-hosted solution",
    value: "Anything around Self-hosted",
  },
  { label: "High Scalability", value: "Scalability" },
  { label: "Internal/workforce identity management", value: "Workforce" },
  {
    label: "Federated login (SSO) integration with 3rd parties",
    value: "Federated login (SSO) in 3rd party apps & Services",
  },
  { label: "Exploring the product", value: "Exploring the product" },
  { label: "Evaluating different solutions", value: "Vendor Evaluation" },
  { label: "Personal side project", value: "Support" },
  { label: "Other", value: "Other" },
]

type InputProps = {
  placeholder?: string
  label: string
  required?: boolean
  options?: { value: string; label: string; hidden?: boolean }[]
  onChange?: (e: string) => void
  error?: {
    _errors: string[]
  }
}

const Input = ({
  error,
  label,
  options,
  onChange,
  required,
  placeholder,
}: InputProps) => {
  const Component = options ? "select" : "input"

  return (
    <div className="inline-flex flex-col items-start justify-start gap-1">
      <div className="inline-flex items-center justify-start gap-8 self-stretch">
        <div className="shrink grow text-sm font-medium text-gray-900">
          {label} {required ? "*" : ""}
        </div>
      </div>
      <div className="relative h-10 w-full bg-white md:h-[52px]">
        <Component
          placeholder={placeholder}
          className="w-full appearance-none rounded px-3 py-2.5 text-sm font-normal text-gray-500 outline-black ring-1 ring-gray-200 md:p-4"
          onChange={(e) => onChange?.(e.target.value)}
        >
          {options?.map((option) => (
            <option
              key={option.value}
              hidden={option.hidden}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Component>
        {options && (
          <div className="pointer-events-none absolute bottom-0 right-4 top-0 flex items-center">
            <ChevronDown />
          </div>
        )}
      </div>
      <div className="text-xs text-error-500">{error?._errors?.join(" ")}</div>
    </div>
  )
}

const iconsMap: { [key: string]: Icon } = {
  TrendingUp: TrendingUp,
  OpenSource: BrandOpenSource,
  ShieldCheck: ShieldCheck,
} as const

const infoCardSchema = z.object({
  title: z.string(),
  description: z.string(),
  icon: z.string().nullish(),
})

export const formHeroPropsSchema = z.object({
  title: z.string().nullish().default(""),
  overline: overlineSchema.nullish(),
  infoCards: infoCardSchema.array().nullish(),
})

const ContactForm = () => {
  const { register, handleSubmit, errors, submitted } = useForm()

  return (
    <div className="relative mx-auto flex w-full flex-col gap-8 rounded-xl border-gray-200 bg-white shadow-indigo-200 md:max-w-[460px] md:border md:p-12 md:shadow-2xl">
      <div
        className={classNames(
          "max-h-0 gap-2 opacity-0 transition-all duration-200",
          {
            "flex max-h-20 opacity-100": submitted,
          },
        )}
      >
        <CheckCircle className="shrink-0 text-succes-500" size={24} />
        <div>
          Thank you for submitting your request. We will get back to you soon.
        </div>
      </div>
      <div className="invisible absolute right-[-24px] px-px md:visible">
        <div className="flex h-10 w-6 items-center justify-center rounded-r-lg border bg-indigo-600 ring-gray-200">
          <img src="/images/ory-logo-text.svg" alt="ory logo" className="w-2" />
        </div>
      </div>
      <div
        className={classNames(
          "flex max-h-[600px] flex-col gap-8 transition-all duration-200",
          {
            hidden: submitted,
          },
        )}
      >
        <h5>Talk to an expert</h5>
        <Input
          required
          label="Title"
          options={titleOptions}
          error={errors?.jobtitle}
          {...register("jobtitle")}
        />
        <Input
          required
          label="Name"
          placeholder="Please enter your name"
          error={errors?.firstname}
          {...register("firstname")}
        />
        <Input
          required
          label="Email"
          placeholder="Please enter your email"
          error={errors?.email}
          {...register("email")}
        />
        <Input
          label="Use case"
          options={reasonOptions}
          error={errors?.what_are_you_interested_in_find_out_}
          {...register("what_are_you_interested_in_find_out_")}
        />
        <Button header="Submit" onClick={handleSubmit} />
      </div>
    </div>
  )
}

type FormHeroProps = z.infer<typeof formHeroPropsSchema>

function FormHero({ title, overline, infoCards }: FormHeroProps) {
  return (
    <Section>
      <Container>
        <div className="col-span-full grid grid-cols-1 gap-x-16 gap-y-16 lg:grid-cols-2">
          <div className="flex flex-col justify-center gap-8">
            <Overline {...overline} />
            <h1 className="text-balance text-6xl font-medium text-gray-900">
              {title}
            </h1>
          </div>
          <div className="flex md:p-2">
            <ContactForm />
          </div>
          <div className="col-span-full flex flex-col gap-8 py-4 md:flex-row">
            {infoCards?.map((card, index) => {
              const CardIcon = card.icon ? iconsMap[card.icon] : () => null

              return (
                <div key={card.title} className="flex flex-1 gap-6">
                  <div className="flex size-8 min-h-8 min-w-8 items-center justify-center rounded bg-indigo-50 text-indigo-600 ring-1 ring-indigo-600">
                    <CardIcon size={18} />
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="text-xl font-medium text-gray-900">
                      {card.title}
                    </h3>
                    <p className="text-base leading-normal text-gray-600">
                      {card.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default FormHero

export const query = graphql`
  fragment FormHero on PagesJson {
    blocks {
      id
      type
      title
      overline {
        prefix
        title
      }
      infoCards {
        title
        description
        icon
      }
    }
  }
`
