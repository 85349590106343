// Copyright © 2025 Ory Corp
// SPDX-License-Identifier: Apache-2.0
import { useEffect, useState } from "react"

export const useSupportsWebGL = () => {
  const [supportsWebgl, setSupportsWebGL] = useState(false)

  useEffect(() => {
    const canvas = document.createElement("canvas")

    const webGlRenderingContext =
      canvas.getContext("webgl") ?? canvas.getContext("webgl2")

    if (webGlRenderingContext !== null) {
      setSupportsWebGL(true)
    }

    canvas.remove()
  }, [])

  return supportsWebgl
}
